export default function AlertError({
  message = 'Email or password is incorrect.'
}: {
  message?: string;
}) {
  return (
    <div>
      <div
        data-testid='alert'
        className='rounded-md p-3 bg-red-100 text-red-900 dark:bg-red-900 dark:text-red-200'
      >
        <div className='relative flex md:flex-row'>
          <div className='flex-shrink-0'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='h-5 w-5 text-red-900 dark:text-red-200'
              data-testid='x-circle'
              aria-hidden='true'
            >
              <circle cx='12' cy='12' r='10'></circle>
              <line x1='15' x2='9' y1='9' y2='15'></line>
              <line x1='9' x2='15' y1='9' y2='15'></line>
            </svg>
          </div>
          <div className='flex flex-grow flex-col sm:flex-row'>
            <div className='ml-2 rtl:mr-3'>
              <h3 className='text-sm font-medium'>{message}</h3>
              <div className='text-sm'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
