import useSWR from 'swr';
import caseService from '@/services/case.service';

const useCase = (id: string | undefined) => {
  const {
    data: caseData,
    mutate: mutateCase,
    isLoading: isLoading
  } = useSWR(`CASE_${id}`, () => caseService.getCaseByID(id), {
    revalidateOnFocus: false
  });

  return {
    isLoading: isLoading,
    caseData,
    mutateCase
  };
};

export default useCase;
