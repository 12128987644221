import { useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function SettingsLayout() {
  const location = useLocation();
  const selectedTab = location.pathname.split('/')[2];
  const [tab, setTab] = useState(selectedTab);

  const onTabChange = (value: string) => {
    setTab(value);
  };

  return (
    <div className='flex flex-col max-w-6xl mx-auto'>
      <div className='mb-6'>
        <h1 className='text-3xl font-semibold'>Settings</h1>
      </div>
      <Tabs
        value={tab}
        onValueChange={onTabChange}
        defaultValue='user'
        className='w-full'
      >
        <TabsList>
          <Link to={'/settings/user'}>
            <TabsTrigger value='user'>Users</TabsTrigger>
          </Link>
          <Link to={'/settings/category'}>
            <TabsTrigger value='category'>Areas of law</TabsTrigger>
          </Link>
          <Link to={'/settings/country'}>
            <TabsTrigger value='country'>Countries</TabsTrigger>
          </Link>
        </TabsList>
        <Outlet />
      </Tabs>
    </div>
  );
}
