// import { useState } from 'react';
// import reactLogo from './assets/react.svg';
// import viteLogo from '/vite.svg';
// import './styles/App.css';

// import { ProtectedRoute } from './components/protected-route';
// import { AuthProvider } from '@/hooks/use-auth-provider';

/** components */
import RunwayTotals from './components/dashboard/runway-totals';
import NetBurnGraph from './components/dashboard/net-burn-graph';
// import RunwayGraph from './components/dashboard/runway-graph';
// import RunwayMovements from './components/dashboard/runway-movements';

function App() {
  // const [count, setCount] = useState(0);
  // const [currentUser, setCurrentUser] = useState(null);

  return (
    <>
      <div className='flex flex-col gap-4 max-w-6xl mx-auto'>
        <RunwayTotals />
        <NetBurnGraph />
        {/* <RunwayGraph /> */}
        {/* <RunwayMovements /> */}
      </div>
    </>
  );
}

export default App;
