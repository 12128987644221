export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function formatUsNumber(number: any) {
  if (!number) {
    return 0.0;
  }

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

export function formatDateTime(date: Date) {
  return new Date(date).toLocaleDateString('en-CR', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
}
