import useSWR from 'swr';
import caseService from '@/services/case.service';

const useCaseList = () => {
  const {
    data: cases,
    mutate: mutateCases,
    isLoading: isLoading
  } = useSWR('CASE_LIST', () => caseService.getAllCases(), {
    revalidateOnFocus: false
  });

  return {
    isLoading: isLoading,
    cases,
    mutateCases
  };
};

export default useCaseList;
