import { useParams, Link } from 'react-router-dom';
import { URL_CASE } from '@/utils/constants';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';

import SectionTitle from '../../components/section-titles';
import Assignments from './assignments';
import Loading from '@/components/page-loader/loading-view';
import { formatDateTime } from '@/utils/global-functions';

/** hooks */
import useCase from '@/hooks/use-case';

export default function Case() {
  const { id } = useParams();
  const { isLoading, caseData } = useCase(id);

  return (
    <div className='flex flex-col max-w-6xl mx-auto'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='down-line-border'>
            <Link className='nav-links' to={URL_CASE}>
              Cases
            </Link>
            <div className='flex flex-1 gap-2 mb-2'>
              <h2 className='data-title'>{caseData && caseData.caseId}</h2>
            </div>
            <p className='data-subtitle'>
              Created at: {caseData && formatDateTime(caseData.createdAt)}
            </p>
            <div className='data-options-container'>
              <Button variant='outline'>Save</Button>
              <Button variant='outline'>Assign lawyer</Button>
              <Button variant='outline'>Add note</Button>
              <Button variant='outline'>Close</Button>
            </div>
          </div>

          <div className='mb-12'>
            <div className='grid grid-cols-2'>
              <div className='flex flex-col gap-y-3 text-sm'>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Name:</div>
                  <div className='col-span-9'>
                    {caseData.firstName} {caseData.lastName}
                  </div>
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Phone:</div>
                  <div className='col-span-9'>{caseData.phone}</div>
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Email:</div>
                  <div className='col-span-9'>{caseData.email}</div>
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Country:</div>
                  <div className='col-span-9'>
                    {caseData.country !== null ? caseData.country.name : ''}
                  </div>
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>State:</div>
                  <div className='col-span-9'>
                    {caseData.state !== null ? caseData.state.name : ''}
                  </div>
                </div>{' '}
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>County:</div>
                  <div className='col-span-9'>
                    {caseData.county !== null ? caseData.county.name : ''}
                  </div>
                </div>
                <div className='grid grid-cols-12 '>
                  <div className='col-span-3 text-gray-400'>Description:</div>
                  <div className='col-span-9'>
                    <p>{caseData.description ?? '--'}</p>
                  </div>
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Areas of law:</div>
                  <div className='col-span-9'>
                    <div className='flex gap-1 flex-wrap'>
                      {caseData.areaOfLaws.map((x: any) => (
                        <span
                          key={x.id}
                          className=' bg-gray-200 px-2 rounded-md text-sm'
                        >
                          {x.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-y-2 text-sm'>
                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Status:</div>
                  <div className='col-span-9'>
                    <div className=''>
                      <Badge className='bg-green-600 flex-0'>
                        {caseData.status}
                      </Badge>
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>
                    Estimated value:
                  </div>
                  <div className='col-span-9'>
                    {caseData.estimatedValue ?? '--'}
                  </div>
                </div>

                <div className='grid grid-cols-12'>
                  <div className='col-span-3 text-gray-400'>Lawyer:</div>
                  <div className='col-span-9'>--</div>
                </div>
              </div>
            </div>
          </div>

          <div className=''>
            <SectionTitle name='Notifications' />
            <Assignments data={caseData.assignments ?? []} />
          </div>
        </>
      )}
    </div>
  );
}
