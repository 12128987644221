import { UserNav } from './user-nav';

export default function Navbar() {
  return (
    <header className='flex sticky top-0 z-[10]'>
      <div className='left-navbar px-4 bg-white nav-border-bottom'>
        <div className='flex w-full items-center justify-between space-y-2'>
          <div>{/* <p className=''>Inicio</p> */}</div>
          <div className='flex items-center space-x-2'>
            <UserNav />
          </div>
        </div>
      </div>
    </header>
  );
}
