import useSWR from 'swr';
import lawyerService from '@/services/lawyer.service';

const useLawyerList = () => {
  const {
    data: lawyerList,
    mutate: mutateLawyerList,
    isLoading: isLoading
  } = useSWR('LAWYER_LIST', () => lawyerService.getAllLawyers(), {
    revalidateOnFocus: false
  });

  return {
    isLoading: isLoading,
    lawyerList,
    mutateLawyerList
  };
};

export default useLawyerList;
