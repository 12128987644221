import APIService from './api.service';
const { VITE_API_BASE_URL } = import.meta.env;

class LawyerService extends APIService {
  constructor() {
    super(VITE_API_BASE_URL || 'http://localhost:3000/v1');
  }

  async getAllLawyers(): Promise<any[]> {
    const response = await this.get(`/lawyer`, {});

    if (response.status !== 200) {
      throw new Error(`cant not fetch data`);
    }

    return response.data.data;
  }

  async getLawyerByID(lawyerId: string | undefined): Promise<any> {
    return this.get(`/lawyer/${lawyerId}`)
      .then((response) => response?.data.data)
      .catch((error) => {
        throw error?.response?.data;
      });
  }

  async createLawyer(data: any, areaOfLaws: any): Promise<any> {
    return this.post(`/lawyer`, {
      ...data,
      areaOfLaws
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }
}

export default new LawyerService();
