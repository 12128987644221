import { useParams } from 'react-router-dom';
import LawyerCard from './components/lawyer-card';
import SectionTitle from '../../components/section-titles';
import Assignments from '../case/assignments';
import Loading from '@/components/page-loader/loading-view';

/** hooks */
import useLawyer from '@/hooks/use-lawyer';
import { Button } from '@/components/ui/button';

// const mock_assignments = [
//   {
//     id: 'fffff',
//     lawyer: {
//       id: '322fdsfsdf',
//       name: 'Tatiana María Mainieri Acuña'
//     },
//     status: 'notified',
//     createdAt: '22-02-2024',
//     confirmDate: null
//   },
//   {
//     id: 'sasdasd',
//     lawyer: {
//       id: 'sdfff',
//       name: 'Ramsés López Sosa'
//     },
//     status: 'accepted',
//     createdAt: '22-02-2024',
//     confirmDate: '22-02-2024'
//   }
// ];

export default function Lawyer() {
  const { id } = useParams();
  const { isLoading, lawyer } = useLawyer(id);

  return (
    <div className='flex flex-col max-w-6xl mx-auto'>
      {isLoading && <Loading />}
      {lawyer !== undefined && (
        <>
          <LawyerCard {...lawyer} />
          <div className='mb-12'>
            <SectionTitle
              name='Address'
              options={<Button variant='outline'>Add address</Button>}
            />
            {/* <Assignments data={mock_assignments} /> */}
          </div>
          <div className='mb-12'>
            <SectionTitle name='Cases' />
            {/* <Assignments data={mock_assignments} /> */}
          </div>

          <div className='mb-12'>
            <SectionTitle name='Notifications' />
            <Assignments data={lawyer.assignments ?? []} showName={false} />
          </div>
        </>
      )}
    </div>
  );
}
