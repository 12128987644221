export default function FieldError({
  message = 'This field is required.'
}: {
  message?: string;
}) {
  return (
    <div
      data-testid='field-error'
      className='text-gray mt-2 flex items-center gap-x-2 text-sm text-red-700'
    >
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='h-3 w-3'
        >
          <circle cx='12' cy='12' r='10'></circle>
          <line x1='12' x2='12' y1='16' y2='12'></line>
          <line x1='12' x2='12.01' y1='8' y2='8'></line>
        </svg>
      </div>
      <p>{message}</p>
    </div>
  );
}
