import { NavLink } from 'react-router-dom';
import { classNames } from '../utils/global-functions';

export default function SidebarLink({ href, name, Icon }: any) {
  return (
    <NavLink
      to={href}
      className={({ isActive, isPending, isTransitioning }: any) =>
        [
          isPending ? 'pending' : '',
          isActive ? 'bg-[#e5e7eb] text-black' : 'hover:bg-regal-gray-variant',
          isTransitioning ? 'transitioning' : '',
          'group flex gap-x-3 rounded-md px-2 py-1 text-[14px]  items-center'
        ].join(' ')
      }
    >
      {({ isActive }) => (
        <>
          <Icon
            className={classNames(
              isActive ? 'text-black' : ' group-hover:bg-regal-gray-variant',
              'h-4 w-4 shrink-0'
            )}
            aria-hidden='true'
          />
          <span className={isActive ? '' : ''}>{name}</span>
        </>
      )}
    </NavLink>
  );
}
