import { BellAlertIcon } from '@heroicons/react/24/outline';
import { classNames } from '@/utils/global-functions';
import { formatDateTime } from '@/utils/global-functions';

export default function Assignments({ data, showName = true }: any) {
  return (
    <div>
      {data.map((x: any) => (
        <div
          key={x.id}
          className='grid grid-cols-2 mb-2 border-b py-2 hover: hover:bg-slate-100'
        >
          <div className='flex gap-2'>
            <div className='bg-gray-200 p-2 rounded-md flex items-center justify-center'>
              <BellAlertIcon className='h-5 w-5 text-black' />
            </div>
            <div className='flex flex-col justify-center'>
              {showName ? (
                <p>{`${x.lawyer.firstName} ${x.lawyer.lastName}`}</p>
              ) : (
                <p>
                  {`Case: ${x.case.caseId}`}{' '}
                  {`${x.case.firstName} ${x.case.lastName}`}
                </p>
              )}
              <p className='text-xs text-gray-400'>
                {formatDateTime(x.createdAt)}
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-2'>
            <div>
              <span
                className={classNames(
                  x.status === 'accepted'
                    ? 'bg-green-200 text-green-950'
                    : ' bg-slate-200',
                  'text-sm  px-2 rounded-sm lowercase'
                )}
              >
                {x.status}
              </span>
            </div>
            <p className='text-xs text-gray-400'>{x.confirmDate}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
