import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/hooks/use-auth';

/** components */
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import FieldError from '@/components/field-error';
import AlertError from '@/components/alert-error';

/** services */
import authService from '@/services/auth.service';

const requiredMessage = 'This field is required.';
const emailFormatMessage = 'Please enter a valid email.';
const errorMessage =
  'Something went wrong. Please try again and contact us if the issue persists.';
const wrongEmailPasswordMessage = 'Email or password is incorrect.';
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [fetchError, setFetchError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    // validate empty field
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: requiredMessage
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }

    // validate email field
    if (name === 'email') {
      if (!value || !emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: prevErrors.email !== '' ? requiredMessage : emailFormatMessage
        }));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleLogin = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const newErrors = {
        email: '',
        password: ''
      };

      setFetchError('');

      if (!formData.email || !emailPattern.test(formData.email)) {
        newErrors.email = emailFormatMessage;
      }

      if (formData.email === '' || formData.email === null) {
        newErrors.email = requiredMessage;
      }

      if (formData.password === '' || formData.password === null) {
        newErrors.password = requiredMessage;
      }

      if (newErrors.email !== '' || newErrors.password !== '') {
        setErrors((prevData) => ({
          ...prevData,
          email: newErrors.email,
          password: newErrors.password
        }));
        setIsLoading(false);
        return;
      }

      const authResult = await authService.authenticateUser(
        formData.email,
        formData.password
      );

      if (authResult) {
        const { id, name, email, role } = authResult.user;
        authService.setAccessToken(authResult.accessToken);
        setIsLoading(false);
        login({
          token: authResult.accessToken,
          isAuthenticated: true,
          user: {
            id,
            name,
            email,
            role
          }
        });
        const queryParams = new URLSearchParams(location.search);
        const nextUrl = queryParams.get('next_url');
        if (nextUrl) {
          navigate(nextUrl, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      } else {
        setFetchError(wrongEmailPasswordMessage);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setFetchError(errorMessage);
      console.error('Login error:', e);
    }
  };

  return (
    <div className='min-h-screen bg-[#f3f4f6]'>
      <div className='class="bg-subtle dark:bg-darkgray-50 flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <h3 className='logo mx-auto mb-auto'>
          <strong>Busca Servicios Legales</strong>
        </h3>
        <div className='text-center sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='text-center text-3xl'>Welcome</h2>
        </div>
        <div className='mb-auto mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white dark:bg-muted mx-2 rounded-md border px-4 py-10 sm:px-10'>
            <form onSubmit={handleLogin} className=''>
              <div className='space-y-6'>
                <div className='space-y-6'>
                  <div>
                    <Label htmlFor='email' className=''>
                      Email address
                    </Label>
                    <Input
                      id='email'
                      name='email'
                      type='text'
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email !== '' && (
                      <FieldError message={errors.email} />
                    )}
                  </div>
                  <div>
                    <Label htmlFor='password' className=''>
                      Password
                    </Label>
                    <Input
                      id='password'
                      name='password'
                      type='password'
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    {errors.password !== '' && (
                      <FieldError message={errors.password} />
                    )}
                  </div>
                </div>
                {fetchError && <AlertError message={fetchError} />}

                <Button
                  type='submit'
                  className='relative w-full'
                  variant='default'
                  size='md'
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Sign in'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
