import { createBrowserRouter, Navigate } from 'react-router-dom';
// import { rootLoader } from './routes/root';

/** layout */
import { AppLayout } from './layouts/app-layout';

/** pages */
import App from './App';
import Error from './pages/error';
import NotFound from './pages/not-found';
import LoginPage from './pages/auth/login';
import NotAuthorized from './pages/no-authorized';

/** components */
import { ProtectedRoute } from './components/protected-route';

/** modules */
import CaseList from './modules/case/case-list';
import LawyerList from './modules/lawyer/lawyer-list';
import SettingsLayout from './modules/settings/settings-layout';
import Case from './modules/case/case';
import Lawyer from './modules/lawyer/lawyer';

/** routes */
import {
  URL_HOME,
  URL_CASE,
  URL_CASE_DETAIL,
  URL_LAWYER,
  URL_LAWYER_DETAIL,
  URL_SETTINGS
} from './utils/constants';

// routers
import settingRoutes from './modules/settings/router';

const router = createBrowserRouter([
  {
    path: 'auth/login',
    element: <LoginPage />,
    errorElement: <Error />
  },
  {
    path: '/login',
    element: <Navigate to='/auth/login' />,
    errorElement: <Error />
  },
  {
    path: '/auth',
    element: <Navigate to='/auth/login' />,
    errorElement: <Error />
  },
  {
    path: URL_HOME,
    element: (
      <ProtectedRoute>
        <AppLayout />
      </ProtectedRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: '',
        element: <App />,
        errorElement: <Error />
      },
      {
        path: URL_CASE,
        element: <CaseList />,
        errorElement: <Error />
      },
      {
        path: URL_CASE_DETAIL,
        element: <Case />,
        errorElement: <Error />
      },
      {
        path: URL_LAWYER,
        element: <LawyerList />,
        errorElement: <Error />
      },
      {
        path: URL_LAWYER_DETAIL,
        element: <Lawyer />,
        errorElement: <Error />
      },
      {
        path: URL_SETTINGS,
        element: <SettingsLayout />,
        errorElement: <Error />,
        children: settingRoutes
      },
      {
        path: '/not-authorized',
        element: <NotAuthorized />,
        errorElement: <Error />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
]);

export default router;
