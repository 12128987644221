import useSWR from 'swr';

/** services */
import settingService from '@/services/setting.service';

const usePublicAreas = () => {
  const { data: areaOfLawList, isLoading: isLoading } = useSWR(
    'PUBLIC_AREA_OF_LAW',
    () => settingService.getPublicAreaOfLaw(),
    {
      revalidateOnFocus: false
    }
  );

  return {
    isLoading: isLoading,
    areaOfLawList
  };
};

export default usePublicAreas;
