import useSWR from 'swr';
import lawyerService from '@/services/lawyer.service';

const useLawyer = (id: string | undefined) => {
  const {
    data: lawyer,
    mutate: mutateLawyer,
    isLoading: isLoading
  } = useSWR(`LAWYER_${id}`, () => lawyerService.getLawyerByID(id), {
    revalidateOnFocus: false
  });

  return {
    isLoading: isLoading,
    lawyer,
    mutateLawyer
  };
};

export default useLawyer;
