import Totals from './totals';

const totals_list = [
  {
    id: 1,
    name: 'Abiertos',
    amount: 22,
    variation: ''
  },
  {
    id: 2,
    name: 'En Progreso',
    amount: 13,
    variation: ''
  },
  {
    id: 3,
    name: 'Sin Asignar',
    amount: 35,
    variation: ''
  },
  {
    id: 4,
    name: 'Cerrados',
    amount: 6,
    variation: ''
  }
];

export default function RunwayTotals() {
  return (
    <div className='flex gap-3'>
      {totals_list.map((x) => (
        <Totals key={x.id} {...x} />
      ))}
    </div>
  );
}
