import { FC } from 'react';
import {
  HomeIcon,
  RocketLaunchIcon,
  RectangleGroupIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline';
import SidebarLink from './sidebar-link';

import {
  URL_HOME,
  URL_CASE,
  URL_LAWYER,
  URL_SETTINGS
} from '../utils/constants';

export interface IAppSidebar {}

const navigation = [
  { name: 'Home', href: URL_HOME, icon: HomeIcon, current: true },
  { name: 'Cases', href: URL_CASE, icon: RocketLaunchIcon, current: false },
  {
    name: 'Lawyers',
    href: URL_LAWYER,
    icon: RectangleGroupIcon,
    current: false
  }
];

const Sidebar: FC<IAppSidebar> = () => {
  return (
    <aside className='px-3 pt-4 aside-bar'>
      <nav className='flex flex-1 flex-col'>
        <ul role='list' className='flex flex-1 flex-col gap-y-1 px-2 -mx-2'>
          {navigation.map((item) => (
            <li key={item.name}>
              <SidebarLink name={item.name} href={item.href} Icon={item.icon} />
            </li>
          ))}
        </ul>
      </nav>
      <div className='mb-6'>
        <SidebarLink name='Settings' href={URL_SETTINGS} Icon={Cog6ToothIcon} />
      </div>
    </aside>
  );
};

export default Sidebar;
