import APIService from './api.service';
const { VITE_API_BASE_URL } = import.meta.env;

class AuthService extends APIService {
  constructor() {
    super(VITE_API_BASE_URL || 'http://localhost:3000/v1');
  }

  async authenticateUser(email: string, password: string): Promise<any> {
    try {
      const response = await this.post(`/auth/login`, {
        email,
        password
      });

      if (response.status !== 200) {
        throw new Error(`cant not fetch data`);
      }
      return response.data;
    } catch (e: any) {
      if (e.response.status === 401 || e.response.status === 404) {
        return null;
      }
      throw new Error('Authentication failed');
    }
  }
}

export default new AuthService();
