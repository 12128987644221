import { Link } from 'react-router-dom';
import { DataTable } from '@/components/table/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { CaretSortIcon, DotsHorizontalIcon } from '@radix-ui/react-icons';
import LoadingView from '@/components/page-loader/loading-view';

import CreateLawyer from './components/create-lawyer';

/** hooks */
import useLawyerList from '@/hooks/use-lawyer-list';
import usePublicAreas from '@/hooks/use-public-areas';

const columns: ColumnDef<any>[] = [
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && 'indeterminate')
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label='Select all'
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label='Select row'
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false
  // },
  {
    accessorKey: 'id',
    header: 'Id',
    show: false
  },
  {
    accessorKey: 'firstName',
    header: ({ column }: any) => {
      return (
        <button
          className='flex justify-between items-center hover:bg-slate-100'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          First name
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </button>
      );
    },
    cell: ({ row }: any) => (
      <Link
        className='text-blue-600 hover:text-blue-800'
        to={`/lawyer/${row.getValue('id')}`}
      >
        {row.getValue('firstName')}
      </Link>
    )
  },
  {
    accessorKey: 'lastName',
    header: ({ column }: any) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Last name
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      );
    }
  },
  {
    accessorKey: 'identity',
    header: ({ column }: any) => {
      return (
        <button
          className='flex justify-between items-center hover:bg-slate-100'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Identity
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </button>
      );
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }: any) => (
      <Badge className='bg-green-600 flex-0'>{row.getValue('status')}</Badge>
    )
  },
  {
    accessorKey: 'email',
    header: ({ column }: any) => {
      return (
        <Button
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Email
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      );
    },
    cell: ({ row }: any) => (
      <div className='lowercase'>{row.getValue('email')}</div>
    )
  },
  {
    id: 'actions',
    enableHiding: false,
    size: 5,
    cell: ({ row }: any) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='ghost' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <DotsHorizontalIcon className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem>
              <Link className='w-full' to={`/lawyer/${row.getValue('id')}`}>
                View
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>Desactivar</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }
  }
];

export default function LawyerList() {
  const { isLoading, lawyerList } = useLawyerList();
  const { areaOfLawList } = usePublicAreas();
  // const [data] = useState(mock_lawyer);
  // const [formData, setFormData] = useState<any>({
  //   firstName: '',
  //   lastName: '',
  //   email: ''
  //   // Agrega más campos según sea necesario
  // });

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(
  //       'https://legal-api-mqe3.onrender.com/lawyer',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(formData)
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     // Limpiar el formulario o manejar la respuesta según sea necesario
  //     setFormData({
  //       name: '',
  //       email: ''
  //     });

  //     console.log('Lawyer created successfully!');
  //   } catch (error) {
  //     // console.error('Error creating lawyer:', error.message);
  //   }
  // };

  return (
    <div className='flex flex-col max-w-6xl mx-auto'>
      <div className='my-6 flex justify-between items-center w-full'>
        <h2 className='text-xl font-semibold'>Lawyers</h2>
        <div>
          <CreateLawyer areaOfLawOptions={areaOfLawList} />
        </div>
      </div>
      {/* <div className='flex justify-between py-2'>
        <p>Abogados</p>
        <Dialog>
          <DialogTrigger asChild>
            <Button>
              <PlusIcon className='mr-1 h-4 w-4' />
              Crear Abogado
            </Button>
          </DialogTrigger>
          <DialogContent className='sm:max-w-[425px]'>
            <DialogHeader>
              <DialogTitle>Crear Abogado</DialogTitle>
            </DialogHeader>
            <div className='grid gap-4 py-4'>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='firstName' className='text-right'>
                  Nombre
                </Label>
                <Input
                  id='firstName'
                  value={formData.firstName}
                  onChange={(e: any) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  className='col-span-3'
                />
              </div>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='lastName' className='text-right'>
                  Apellidos
                </Label>
                <Input
                  id='lastName'
                  value={formData.lastName}
                  onChange={(e: any) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  className='col-span-3'
                />
              </div>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='email' className='text-right'>
                  Correo
                </Label>
                <Input
                  id='email'
                  value={formData.email}
                  onChange={(e: any) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className='col-span-3'
                />
              </div>
            </div>
            <DialogFooter>
              <Button type='submit' onClick={handleSubmit}>
                Save changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        {isLoading ? (
          <LoadingView />
        ) : (
          <DataTable columns={columns} data={lawyerList ?? []} />
        )}
      </div>
    </div>
  );
}
