import { useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import { format, parseISO } from 'date-fns';
// import { formatUsNumber } from '../../utils/global-functions';

// const renderCustomizedLabel = (props) => {
//   const { x, y, width, height, value } = props;
//   const radius = 2;

//   return (
//     <g>
//       <foreignObject x={x} y={y} width={82} height={50}>
//         <div className='bg-red-300 rounded-md flex justify-center align-middle flex-col p-1'>
//           <p>Total:</p>
//           <p className='text-sm'>{formatUsNumber(value * 200)}</p>
//         </div>
//       </foreignObject>
//     </g>
//     // <g>
//     //   <circle cx={x + width / 2} cy={y - radius} r={radius} fill='#8884d8' />
//     //   <text
//     //     x={x + width / 2}
//     //     y={y - radius}
//     //     fill='#000'
//     //     textAnchor='middle'
//     //     dominantBaseline='middle'
//     //   >
//     //     {/* {value.split(' ')[1]} */}
//     //     {formatUsNumber(value)}
//     //   </text>
//     // </g>
//   );
// };

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='p-4 rounded-md bg-white shadow-xl border-b-1'>
        <p className='font-medium mb-2'>Cases</p>
        {/* <p className='text-md mb-1'>{formatUsNumber(payload[0].value)}</p> */}
        <p className='text-md mb-1'>{payload[0].value}</p>
        <p className='text-sm'>
          {parseISO(label) ? format(parseISO(label), 'MMM, yyyy') : ''}
        </p>
      </div>
    );
  }

  return null;
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, height, value } = props;
  const isNegative = value < 0;

  return (
    <text
      x={x + width / 2}
      y={isNegative ? y + height + 15 : y - 10}
      fill='#000'
      textAnchor='middle'
      fontSize={12}
    >
      {/* {formatUsNumber(value)} */}
      {value}
    </text>
  );
};

export default function NetBurnGraph() {
  const [netBurnData] = useState([
    {
      id: 1,
      date: '2024-01-01',
      value: 200
    },
    {
      id: 2,
      date: '2024-02-01',
      value: 450
    },
    {
      id: 3,
      date: '2024-03-01',
      value: 300
    },
    {
      id: 4,
      date: '2024-04-01',
      value: 524
    },
    {
      id: 5,
      date: '2024-05-01',
      value: 245
    },
    {
      id: 6,
      date: '2024-06-01',
      value: 123
    },
    {
      id: 7,
      date: '2024-07-01',
      value: 233
    },
    {
      id: 8,
      date: '2024-08-01',
      value: 45
    },
    {
      id: 9,
      date: '2024-09-01',
      value: 23
    },
    {
      id: 10,
      date: '2024-10-01',
      value: 155
    }
  ]);

  const [focusBar, setFocusBar] = useState<number | null | undefined>(null);
  const [mouseLeave, setMouseLeave] = useState(true);

  return (
    <div className='dashboard-card-container'>
      <p className='mb-8'>Cases created by month</p>
      <div style={{ height: '18vw' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            data={netBurnData}
            onMouseMove={({ isTooltipActive, activeTooltipIndex }) => {
              if (isTooltipActive) {
                setFocusBar(activeTooltipIndex);
                setMouseLeave(false);
              } else {
                setFocusBar(null);
                setMouseLeave(true);
              }
            }}
          >
            <CartesianGrid opacity={0.3} vertical={false} />
            <XAxis
              dataKey='date'
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              tickFormatter={(str) => format(parseISO(str), 'MMM, yyyy')}
            />
            <YAxis
              dataKey='value'
              axisLine={false}
              tickLine={false}
              // tickCount={6}
              tick={{ fontSize: 12 }}
              // tickFormatter={(tick) => (tick !== 0 ? tick : '')}
            />
            <Bar dataKey='value' fill='#8884d8'>
              {netBurnData.map((entry, index) => (
                <Cell
                  key={index}
                  fill={
                    focusBar === index || mouseLeave
                      ? '#2B5CE7'
                      : 'rgba(43, 92, 231, 0.5)'
                  }
                />
              ))}
              <LabelList content={renderCustomizedLabel} />
            </Bar>
            <Tooltip content={<CustomTooltip />} cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
