import { Link } from 'react-router-dom';
import { URL_LAWYER } from '@/utils/constants';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { formatDateTime } from '@/utils/global-functions';
export default function LawyerCard({
  firstName,
  lastName,
  identity,
  status,
  phone,
  email,
  areaOfLaws,
  number,
  createdAt
}: //   cases,
//   assignments,
any) {
  return (
    <div>
      <>
        <div className='down-line-border'>
          <Link className='nav-links' to={URL_LAWYER}>
            Lawyers
          </Link>
          <div className='flex flex-1 gap-2 mb-2'>
            <h2 className='data-title'>{`${firstName} ${lastName}`}</h2>
          </div>
          <p className='data-subtitle'>
            Created at: {formatDateTime(createdAt)}
          </p>
          <div className='data-options-container'>
            <Button variant='outline'>Edit</Button>
          </div>
        </div>

        <div className='mb-12'>
          <div className='grid grid-cols-2'>
            <div className='flex flex-col gap-y-2 text-sm'>
              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>First name:</div>
                <div className='col-span-9'>{firstName}</div>
              </div>

              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Last name:</div>
                <div className='col-span-9'>{lastName}</div>
              </div>

              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Identity:</div>
                <div className='col-span-9'>{identity}</div>
              </div>

              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Areas of law:</div>
                <div className='col-span-9'>
                  <div className='flex gap-1 flex-wrap'>
                    {areaOfLaws.map((x: any) => (
                      <span
                        key={x.id}
                        className=' bg-gray-200 px-2 rounded-md text-sm'
                      >
                        {x.name}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-y-2 text-sm'>
              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Status:</div>
                <div className='col-span-9'>
                  <div className=''>
                    <Badge className='bg-green-600 flex-0'>{status}</Badge>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Phone:</div>
                <div className='col-span-9'>{phone}</div>
              </div>

              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Email:</div>
                <div className='col-span-9'>{email}</div>
              </div>

              <div className='grid grid-cols-12'>
                <div className='col-span-3 text-gray-400'>Lawyer number:</div>
                <div className='col-span-9'>{number}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
