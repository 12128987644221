import { createContext, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './use-local-storage';

interface AuthContextProps {
  token: string | null;
  user: {
    id: string;
    name: string;
    email: string;
    role: string;
  } | null;
  loading: boolean;
  err: any;
  isAuthenticated: boolean;
  login: (e?: any) => void;
  logOut: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  user: null,
  loading: false,
  err: null,
  isAuthenticated: false,
  login: (e?: any) => {
    console.log(e);
  },
  logOut: () => {}
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage('user', null);

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setUser({
      accessToken: data.token,
      isAuthenticated: true,
      user: {
        ...data.user
      }
    });
    // navigate('/dashboard');
  };

  // {
  //   token: null,
  //   user: data,
  //   loading: false,
  //   err: null,
  //   isAuthenticated: true
  // }

  //   const login = useCallback((response) => {
  //     storeCredentials(response.credentials);
  //     setCurrentUser(response.user);
  //   }, []);

  // call this function to sign out logged in user
  const logOut = () => {
    setUser({
      isAuthenticated: false,
      user: null,
      token: null
    });
    // navigate('/', { replace: true });
  };

  const value = useMemo<any>(
    () => ({
      ...user,
      login,
      logOut
    }),
    [user]
  );
  // const contextValue = useMemo(
  //   () => ({
  //     user,
  //     login,
  //     logout
  //   }),
  //   [user, login, logout]
  // );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
