import APIService from './api.service';
const { VITE_API_BASE_URL } = import.meta.env;

class SettingService extends APIService {
  constructor() {
    super(VITE_API_BASE_URL || 'http://localhost:3000/v1');
  }

  async getPublicAreaOfLaw(): Promise<any[]> {
    const response = await this.get(`/public/area_of_law`, {});

    if (response.status !== 200) {
      throw new Error(`cant not fetch data`);
    }

    return response.data;
  }

  async getUsers(): Promise<any[]> {
    const response = await this.get(`/setting/user`, {});

    if (response.status !== 200) {
      throw new Error(`cant not fetch data`);
    }

    return response.data.data;
  }

  async getAreaOfLaw(): Promise<any[]> {
    const response = await this.get(`/setting/area_of_law`, {});

    if (response.status !== 200) {
      throw new Error(`cant not fetch data`);
    }

    return response.data.data;
  }

  async getCountries(): Promise<any[]> {
    const response = await this.get(`/setting/country`, {});

    if (response.status !== 200) {
      throw new Error(`cant not fetch data`);
    }

    return response.data.data;
  }

  async createBranch(name: string, description: string): Promise<any> {
    return this.post(`/setting/area_of_law`, { name, description })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  async updateBranch(
    id: number,
    name: string,
    description: string,
    status: string
  ): Promise<any> {
    return this.put(`/setting/area_of_law/${id}`, { name, description, status })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  // async createBranch(areaOfLaw: any): Promise<any> {
  //   return this.post(`/setting/area_of_law/${areaOfLaw.id}`, { ...areaOfLaw })
  //     .then((response) => {
  //       return response?.data;
  //     })
  //     .catch((error) => {
  //       throw error?.response;
  //     });
  // }
}

export default new SettingService();

//   async createBudget(
//     data: Partial<IBudget>
//     // user: ICurrentUserResponse | undefined
//   ): Promise<IBudget> {
//     return this.post(`/api/v1/budget`, data)
//       .then((response) => {
//         // if (trackEvent)
//         //   trackEventServices.trackProjectEvent(response.data, 'CREATE_PROJECT', user)
//         return response?.data
//       })
//       .catch((error) => {
//         throw error?.response
//       })
//   }

//   async getBudgetById(budgetId: string): Promise<IBudget> {
//     return this.get(`/api/v1/budget/${budgetId}`)
//       .then((response) => response?.data)
//       .catch((error) => {
//         throw error?.response?.data
//       })
//   }

//   async getBudgets(): Promise<IBudget[]> {
//     const response = await this.get(`/api/v1/budget`, {})

//     if (response.status !== 200) {
//       throw new Error(`cant not fetch data`)
//     }

//     return response.data
//   }

//   async deleteBudget(budgetId: string): Promise<any> {
//     return this.delete(`/api/v1/budget/${budgetId}`)
//       .then((response) => {
//         return response?.data
//       })
//       .catch((error) => {
//         throw error?.response?.data
//       })
//   }
