import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';

export interface IAppLayout {
  children?: ReactNode;
}

export const AppLayout: FC<IAppLayout> = () => {
  return (
    <div className='main-container'>
      <header className='bg-blue-500 ebmgCq'>
        <div className='left-navbar px-5'>
          <p className='font-medium'>Busca Servicios Legales</p>
        </div>
        <Sidebar />
      </header>
      <main className='iiJvdz' tabIndex={-1}>
        <Navbar />
        <div className='px-4 pt-4'>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
