import { Suspense } from 'react';
import LoadingView from './loading-view';

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<LoadingView />}>
      <Component {...props} />
    </Suspense>
  );
export default Loadable;
