import { Navigate } from 'react-router-dom';
import { useAuth } from '@/hooks/use-auth';

export type User = {
  name: string;
  email: string;
  role: 'admin' | 'user' | 'moderator';
};

export type AuthStateType = {
  token: string;
  user: User | null;
  loading: boolean;
  err: any;
  isAuthenticated: boolean;
};

export const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to='/auth/login' replace />;
};
