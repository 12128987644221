import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import { mutate } from 'swr';

/** services */
import lawyerService from '@/services/lawyer.service';

export default function CreateLawyer({ areaOfLawOptions }: any) {
  const [lawyer, setLawyer] = useState({
    firstName: '',
    lastName: '',
    identity: '',
    number: '',
    phone: '',
    email: ''
  });
  const [areaOfLawSelected, setAreaOfLawSelected] = useState<any>([]);

  const handleCreateLawyer = async () => {
    try {
      await lawyerService.createLawyer(lawyer, areaOfLawSelected);
      mutate('LAWYER_LIST');
      setOpen(false);
    } catch (error) {
      console.log('🚀 ~ handleCreateLawyer ~ error:', error);
    }
  };
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add lawyer</Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-2xl'>
        <DialogHeader>
          <DialogTitle>Create lawyer</DialogTitle>
          <DialogDescription>--</DialogDescription>
        </DialogHeader>
        <div className=''>
          <div className='grid grid-cols-2 gap-2'>
            <div>
              <Label htmlFor='firstName' className=''>
                First name
              </Label>
              <Input
                id='firstName'
                value={lawyer.firstName}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, firstName: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor='lastName' className=''>
                Last name
              </Label>
              <Input
                id='lastName'
                value={lawyer.lastName}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, lastName: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor='identity' className=''>
                Identity
              </Label>
              <Input
                id='identity'
                value={lawyer.identity}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, identity: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor='number' className=''>
                School number
              </Label>
              <Input
                id='number'
                value={lawyer.number}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, number: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor='phone' className=''>
                Phone
              </Label>
              <Input
                id='phone'
                value={lawyer.phone}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, phone: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor='email' className=''>
                Email
              </Label>
              <Input
                id='email'
                value={lawyer.email}
                onChange={(e: any) =>
                  setLawyer({ ...lawyer, email: e.target.value })
                }
              />
            </div>
          </div>
          {areaOfLawOptions !== null &&
            areaOfLawOptions !== undefined &&
            areaOfLawOptions.length > 0 && (
              <div className='mt-4'>
                <Label className=''>Area of law</Label>
                <div className='flex flex-grow w-full gap-4 my-2'>
                  {areaOfLawOptions?.map((x: any) => (
                    <div
                      key={x.id}
                      className='flex items-center gap-2 hover:cursor-pointer'
                    >
                      <Checkbox
                        id={x.id}
                        checked={areaOfLawSelected?.includes(x.id)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? setAreaOfLawSelected([...areaOfLawSelected, x.id])
                            : setAreaOfLawSelected(
                                areaOfLawSelected?.filter(
                                  (value: any) => value !== x.id
                                )
                              );
                        }}
                      />
                      <label
                        htmlFor={x.id}
                        className='text-sm font-medium leading-none hover:cursor-pointer peer-disabled:opacity-70 select-none'
                      >
                        {x.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
        <DialogFooter className='sm:justify-end'>
          <DialogClose asChild>
            <Button type='button' variant='secondary'>
              Cancel
            </Button>
          </DialogClose>
          <Button onClick={handleCreateLawyer}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
