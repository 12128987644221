import { DataTable } from '@/components/table/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';
import Loading from '@/components/page-loader/loading-view';
import { formatDateTime } from '@/utils/global-functions';

/** hooks */
import useCaseList from '@/hooks/use-case-list';

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
    show: false
  },
  {
    accessorKey: 'caseId',
    header: 'Number',
    size: 5
  },
  {
    header: 'Name',
    cell: ({ row }: any) => (
      <Link
        className='text-blue-600 hover:text-blue-800'
        to={`/case/${row.getValue('id')}`}
      >
        {`${row.original.firstName} ${row.original.lastName}`}
      </Link>
    )
  },
  {
    accessorKey: 'createdAt',
    header: 'Created at',
    cell: ({ row }: any) => (
      <span>{formatDateTime(row.original.createdAt)}</span>
    )
  },
  // {
  //   accessorKey: 'state.name',
  //   header: 'Provincia'
  // },
  {
    accessorKey: 'lawyer.name',
    header: 'Lawyer'
  },
  {
    accessorKey: 'status',
    header: 'Status'
  },
  {
    id: 'actions',
    enableHiding: false,
    size: 5,
    cell: ({ row }: any) => {
      // const user = row.original;
      // console.log('🚀 ~ user:', user);

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='ghost' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <DotsHorizontalIcon className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem>
              <Link className='w-full' to={`/case/${row.getValue('id')}`}>
                Ver
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>Archivar</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }
  }
];

// const mock_case = [
//   {
//     id: 'sdfasfd',
//     number: 34,
//     name: 'Juan Carlos',
//     createdAt: '12-12-2024',
//     state: {
//       name: 'San José',
//       id: 2
//     },
//     owner: {
//       id: '2332',
//       name: 'Tatiana María Mainieri'
//     },
//     status: 'enabled'
//   }
// ];

export default function CaseList() {
  const { isLoading, cases } = useCaseList();
  // const [data] = useState(mock_case);

  return (
    <div className='flex flex-col max-w-6xl mx-auto'>
      <div className='my-6 flex justify-between items-center w-full'>
        <h2 className='text-xl font-semibold'>Cases</h2>
        <Button>Add case</Button>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <DataTable columns={columns} data={cases ?? []} />
      )}
    </div>
  );
}
