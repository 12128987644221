import type { RouteObject } from 'react-router';
import { redirect } from 'react-router-dom';

import { Users, Categories, Countries } from './loader';

const routes: RouteObject[] = [
  {
    index: true,
    loader: async () => redirect('/settings/user')
  },
  {
    path: '/settings/user',
    element: <Users />
  },
  {
    path: '/settings/category',
    element: <Categories />
  },
  {
    path: '/settings/country',
    element: <Countries />
  }
];

export default routes;
