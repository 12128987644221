import type { FC } from 'react';
// import NProgress from 'nprogress'

const LoadingView: FC = () => {
  return (
    <>
      <div className='flex flex-auto flex-col justify-center items-center p-4 md:p-5'>
        <div className='flex justify-center'>
          <div
            className='animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-blue-600 rounded-full'
            role='status'
            aria-label='loading'
          />
        </div>
      </div>
    </>
  );
};

export default LoadingView;
